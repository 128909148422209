import { Entypo } from '@expo/vector-icons';
import * as DocumentPicker from 'expo-document-picker';
import React, { useState } from 'react';
import { Alert, Pressable, StyleSheet } from 'react-native';
import ClipIcon from "../../../assets/image/clip-icon.svg";
import { colors } from '../../../core/constants/colors';
import IconButton from '../../../core/ui/IconButton';
import Text from '../../../core/ui/Text';
import { isWeb } from '../../../core/utils/responsive';

const PickFileCard = ({ pickedDocument, setAllowedFilesError, component, onDocumentPicked, containerStyle }) => {
    const [pickedFile, setPickedFile] = useState("");

    const handlePickedFile = async () => {
        if (pickedDocument !== "") return Alert.alert("Seulement une seule pièce jointe peut être ajoutée.");
        const result = await DocumentPicker.getDocumentAsync();

        if (result.type !== 'cancel') {
            try {
                if (!component.allowFileImage && result.name.startsWith("image")) {
                    setAllowedFilesError("Les fichiers d'images ne sont pas autorisés.");
                    setPickedFile("");
                    onDocumentPicked("");
                    return
                }
                if (component.allowFilePdf === false && result.name.endsWith("pdf")) {
                    setAllowedFilesError("Les fichiers Pdf ne sont pas autorisés.");
                    setPickedFile("");
                    onDocumentPicked("");
                    return
                }
                if (result.size > 20000000) {
                    setAllowedFilesError("La taille de votre fichier est trop grande, merci de la réduire.");
                    setPickedFile("");
                    onDocumentPicked("");
                    return Alert.alert("La taille de votre fichier est trop grande, merci de la réduire.");
                }
                const ext = (result.name || result.uri || result.file.name).split('.').pop().toLowerCase();
                if ((!['pdf'].includes(ext) && !result?.mimeType?.startsWith('image/')) || ext === "gif") {
                    setAllowedFilesError("Le format de ce fichier n'est pas pris en charge. Le fichier doit être une image ou un document de type pdf.");
                    setPickedFile("");
                    onDocumentPicked("");
                    return;
                }

                setPickedFile(result.name);
                onDocumentPicked(result);
                setAllowedFilesError("");
            } catch (err) {
                console.error('Error picking document', err);
            }
        }
    }

    const handleDeletedFile = () => {
        onDocumentPicked("");
        setPickedFile("");
    }

    return (
        <Pressable
            onPress={handlePickedFile}
            style={[styles.container, containerStyle]}
            testID="attachment-card"
            accessibilityLabel={isWeb ? "Joindre un fichier ou une image" : "Joindre un fichier"}
            accessibilityRole='button'
        >
            <ClipIcon aria-label="icône agraphe" />
            {pickedFile ? (
                <>
                    <Text style={styles.documentTitle} numberOfLines={1} ellipsizeMode="head">{pickedFile}</Text>
                    <IconButton onPress={handleDeletedFile} icon={<Entypo name="circle-with-cross" size={24} color={colors.primary400} />} style={styles.icon} />
                </>
            ) : (
                <Text style={styles.text}>Joindre un fichier {isWeb && "ou une image"}</Text>
            )}
        </Pressable>
    );
};


export default PickFileCard

const styles = StyleSheet.create({
    container: {
        height: 60,
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        paddingHorizontal: 30,
        borderRadius: 10,
        borderWidth: 1,
        borderColor: colors.primary300,
        borderStyle: "dashed"
    },
    text: {
        color: colors.primary300,
        marginLeft: 8
    },
    documentTitle: {
        flex: 1,
        color: colors.primary300,
        marginLeft: 8
    },
    icon: {
        marginRight: -25,
        marginLeft: 5
    }
})